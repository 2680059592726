<template>
  <v-card class="elevation-0">
    <v-card-title>
      <v-icon color="yellow darken-2">mdi-star</v-icon>
      <v-icon color="yellow darken-2">mdi-star</v-icon>
      <v-icon color="yellow darken-2">mdi-star</v-icon>
      {{ title }}
      <v-spacer></v-spacer>

      <v-text-field
        v-on:keyup.enter="searchOnEnter"
        v-on:blur="searchOnEnter"
        append-icon="mdi-magnify"
        hint="NIP, Nama (tekan ENTER untuk memulai cari)"
        label="Cari data"
      ></v-text-field>
      <v-btn @click="showFilterDialog" icon fab color="blue" small class="ml-2">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <v-card
        class="ml-2 mr-2 elevation-0 grey lighten-4"
        v-show="showFilter === true"
      >
        <v-card-text class="blue lighten-5 rounded-lg">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                  solo-inverted
                v-model="filterFdivisions"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                multiple
                label="Dinas/OPD"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="9">
              <v-autocomplete
                  solo-inverted
                v-model="filterFtemplateReg"
                :items="itemsFTemplateReg"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Jenis Registrasi Layanan"
                multiple
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="3" v-if="true">
              <v-autocomplete
                  solo-inverted
                v-model="filterStatusUsulan"
                :items="itemsStatusUsulan"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Status Usulan"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                  solo-inverted
                v-model="filterPeriodeTahun"
                :items="itemsPeriodeTahun"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Periode Tahun"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                  solo-inverted
                  v-model="filterPeriodeBulan"
                :items="itemsPeriodeBulan"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Periode Bulan"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-autocomplete
                  solo-inverted
                v-model="filterJenisJabatanIds"
                :items="itemsFJenisJabatan"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Jenis Jabatan"
                hide-details
                multiple
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" sm="2" md="2">
              <v-btn block color="primary" class="rounded-lg" @click="runExtendedFilter">
                Terapkan
                <v-icon small class="ml-1" color="green lighten-2"
                  >mdi-filter</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <v-row align="center" class="ml-4 mr-4 mt-4">

      <v-spacer></v-spacer>

      <v-menu offset-y style="align-items: start">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab dark x-small color="blue" v-bind="attrs" v-on="on">
            <v-icon>mdi-view-grid</v-icon>
          </v-btn>
        </template>
        <v-list color="grey lighten-4" class="mr">
          <v-divider></v-divider>
          <v-list-item>
            <v-btn
                plain
                elevation="0"
                color="gray darken-1"
                small
                @click="exportData"
            >
              <v-icon color="green" small>mdi-file-excel</v-icon>
              Export Excel
            </v-btn>
          </v-list-item>

        </v-list>
      </v-menu>
    </v-row>

    <v-data-table
      v-model="selectedItems"
      :single-select="!multiSelect"
      :show-select="multiSelect"
      :headers="headers"
      :items="ftRegistrasiFiltered"
      :page.sync="currentPage"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-0"
      @page-count="totalTablePages = totalPaginationPages"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4 mt-6">
          <v-switch
            v-model="multiSelect"
            :label="multiSelect ? 'Multi Select' : 'Single Select'"
            x-small
            v-if="false"
          ></v-switch>
          <v-btn
            v-if="false"
            fab
            dark
            color="red accent-4"
            x-small
            :disabled="!multiSelect"
            class="mr-2"
            @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="false"
            fab
            dark
            color="green"
            x-small
            @click="showDialogNew"
            :disabled="multiSelect"
            class="ml-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="approvalDialogShow(item)"
          fab
          dark
          color="success"
          x-small
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.actionsExtended`]="{ item }">
        <div>
          <v-btn
            color="indigo lighten-1"
            class="white--text"
            x-small
            @click="takedownBerkas(item)"
          >
            Takedown Berkas
          </v-btn>
          <v-btn
              color="yellow darken-3"
              class="white--text"
              x-small
              @click="showDialogEditStatus(item)"
          >
            Ubah Status
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.verifikatorBean`]="{ item }">
        <div
          v-if="item.verifikatorBean !== 0"
          class="caption success--text text--darken-3"
        >
          <div>
            {{ lookupVerifikator(item.verifikatorBean).fullName }}
          </div>

        </div>
        <div
          v-if="item.verifikatorBean === 0"
          class="font-weight-bold grey--text"
        >
          -
        </div>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ index + (currentPage - 1) * pageSize + 1 }}
      </template>

      <template v-slot:[`item.avatar`]="{ item }">
        <div class="text-center">
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
              width="70px"
              height="70px"
              class="ma-2 rounded"
          >
          </v-img>
          <v-icon v-if="item.approvedStatus===1" color="green" class="mt-n10" small>mdi-check-circle</v-icon>
          <v-icon  v-if="item.approvedStatus===2" color="red lighten-1" class="mt-n10" small>mdi-close</v-icon>
        </div>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" @click="showRiwayat(item)">
              <div class="subtitle-1">
                {{ item.description }}
              </div>
              <div class="font-weight-bold">
                {{ item.nip }}
              </div>
            </div>
          </template>
          <span>Lihat Riwayat Aktivitas</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.approvedStatus`]="{ item }">
        <div>
          <v-chip
              dark
              x-small
              color="grey"
              v-if="item.approvedStatus === 0">Menunggu</v-chip>
          <v-chip
            dark
            x-small
            color="success"
            v-else-if="item.approvedStatus === 1">Disetujui</v-chip>
          <v-chip
              dark
              x-small
              color="red lighten-1"
              v-else-if="item.approvedStatus === 2">Ditolak</v-chip>
        </div>
        <div>
<!--          {{ item.validasiStatus}}-->
          <v-btn
              v-if="item.validasiStatus === 1"
              class="my-2"
              outlined
              x-small
              @click="downloadBerkas(item)"
              color="success"
          >
            Download Berkas
            <v-icon class="ml-1" x-small color="success">mdi-download</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.validasiStatus`]="{ item }">
        <div class="py-2">
          <v-chip
              dark
              small
              color="grey darken-1"
              v-if=" item.submitted === false && item.validasiReasonCode===0 && item.validasiStatus === 0 ">
            Draft
          </v-chip>

          <v-chip
            dark
            small
            color="orange darken-1"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean === 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 0
            "
            >Menunggu Verifikasi</v-chip
          >
          <v-chip
            dark
            small
            color="blue-grey darken-1"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean === 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 1
            ">Menunggu Re-verifikasi
          </v-chip>
          <v-chip
            dark
            small
            color="primary"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean > 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 0
            ">
            Proses Verifikasi
          </v-chip>
          <v-chip
            dark
            small
            color="teal"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean > 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 1
            ">
            Proses Re-verifikasi
          </v-chip>
          <v-chip
            dark
            small
            color="red"
            v-else-if="item.validasiStatus === 2 || item.validasiStatus ===0">
            {{ item.validasiReasonCode === 1 ? "Dikembalikan" : "Tidak Valid"}}
          </v-chip>
          <v-chip
            dark
            small
            color="success"
            v-else-if="item.validasiStatus === 1"
          >
            <v-icon class="mr-1">mdi-check-circle</v-icon>
            VALID
          </v-chip>

          <div
            class="mt-2 grey--text caption"
            v-if="(item.validasiStatus === 2 || item.validasiStatus ===0) && item.validasiReasonCode === 1"
          >
            Berkas Tidak Sesuai
          </div>
          <div
            class="mt-2 grey--text caption"
            v-if="(item.validasiStatus === 2 || item.validasiStatus ===0) && item.validasiReasonCode === 2"
          >
            Tidak Memenuhi Syarat
          </div>
          <div class="caption" v-if="(item.validasiStatus === 2 || item.validasiStatus ===0)">
            {{ item.approvedReasons }}
          </div>
          <div>
              <v-btn
                v-if="item.validasiStatus === 1"
                class="my-2"
                outlined
                x-small
                @click="downloadBerkas(item)"
                color="success"
              >
                Download Berkas
                <v-icon class="ml-1" x-small color="success"
                  >mdi-download</v-icon
                >
              </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:[`item.periodeMonth`]="{ item }">
        <div
            class="caption"
          v-if="lookupFTemplateReg(item.ftemplateRegBean).showPeriodeBulan ||
            lookupFTemplateReg(item.ftemplateRegBean).showPeriodeTahun">
          <span
              class="font-weight-bold"
            v-if="lookupFTemplateReg(item.ftemplateRegBean).showPeriodeBulan"
            >{{ lookupPeriodeBulan(item.periodeMonth).description }}
          </span>
          <span
            class="font-weight-bold"
            v-if="
              lookupFTemplateReg(item.ftemplateRegBean).showPeriodeBulan &&
              lookupFTemplateReg(item.ftemplateRegBean).showPeriodeTahun
            "></span>
          <span
              class="font-weight-bold"
              v-if="lookupFTemplateReg(item.ftemplateRegBean).showPeriodeTahun"
            >{{ item.periodeYear }}
          </span>
        </div>
        <div class="mb-2">
          {{ lookupFJenisJabatan(item.fjenisJabatanBean).description }}
        </div>

        <div class="caption">
          {{ item.trDate }}
        </div>

      </template>

      <template v-slot:[`item.ftemplateRegBean`]="{ item }">
        <div class="blue--text font-weight-bold">
          {{ lookupFTemplateReg(item.ftemplateRegBean).description }}
        </div>
        <div class="text-caption small">
          {{ lookupFDivision(item.fdivisionBean).description }}
        </div>
      </template>
    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col cols="4" md="2" sm="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per page"
          ></v-select>
        </v-col>
        <v-col cols="10" md="9" sm="8" align="right">
          <v-pagination
            v-model="currentPage"
            :length="totalPaginationPages"
            total-visible="8"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <FtRegistrasiApprovalDialog
      ref="refFtRegistrasiApprovalDialog"
      @eventFromFormDialogEdit="registrasiApprovalDialogUpdate"
    ></FtRegistrasiApprovalDialog>

    <ConfirmDialog
      ref="refConfirmDialog"
      @eventFromOkeConfirmDialog="confirmDialogOke"
    ></ConfirmDialog>


    <v-dialog width="100px" v-model="dialogLoading">
      <v-card class="px-4 py-7">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="40"
              :width="5"
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <FtRegistrasiLogDialog
        ref="refFtRegistrasiLogDialog">

    </FtRegistrasiLogDialog>
    <RejectConfirmDialog
        ref="refRejectConfirmDialog"
        @eventFromRejectConfirmDialog="confirmTakedown"
    ></RejectConfirmDialog>
    <FtRegistrasiApprovalStatusDialog
        :formMode.sync="formMode"
        ref="refFormEditStatusDialog"
        @eventFromFormDialogEdit="saveDataEditStatus"
    >

    </FtRegistrasiApprovalStatusDialog>
  </v-card>
</template>

<script>
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";
import UserService from "@/services/user-service";
import FDivisionService from "@/services/apiservices/f-division-service";
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";
import FJenisJabatanService from "@/services/apiservices/f-jenis-jabatan-service";
import FJenisProsedurService from "@/services/apiservices/f-jenis-prosedur-service";
import FJenisKenaikanPangkatService from "@/services/apiservices/f-jenis-kenaikan-pangkat-service";
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";


import { EPeriodeMonths } from "@/models/e-periode-month";
import FormMode from "@/models/form-mode";
import FtRegistrasi from "@/models/ft-registrasi";
import FileService from "@/services/apiservices/file-service";
import FtRegistrasiFilter from "@/models/payload/ft-registrasi-filter";

import * as XLSX from 'xlsx';
import ERole from "@/models/e-role";
import FJenisKartuService from "@/services/apiservices/f-jenis-kartu-service";
import FtRegistrasiLog from "@/models/ft-registrasi-log";
import ERegistrasiLog, {ERegistrasiLogs} from "@/models/e-registrasi-log";
import FtRegistrasiLogService from "@/services/apiservices/ft-registrasi-log-service";
import FtRegistrasiLogDialog from "@/components/admin_approval/FtRegistrasiLogDialog.vue";
import FtRegistrasiApprovalDialog from "@/components/admin_approval/FtRegistrasiApprovalDialog.vue";
import ApiFonnteService from "@/services/apiservices-ext/api-fonnte-service";
import RejectConfirmDialog from "@/components/utils/RejectConfirmDialog.vue";
import FtRegistrasiApprovalStatusDialog from "@/components/admin_approval/FtRegistrasiApprovalStatusDialog.vue";

export default {
  name: "ValidasiTable",
  components: {
    FtRegistrasiApprovalStatusDialog,
    RejectConfirmDialog,
    FtRegistrasiApprovalDialog,
    FtRegistrasiLogDialog,
    ConfirmDialog,

  },
  data() {
    return {
      itemsERegistrasiLog: ERegistrasiLogs,
      ERegistrasiLog: ERegistrasiLog,
      title: "APPROVAL",
      snackbar: false,
      snackBarMesage: "",

      dialogLoading: false,
      loading: false,

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 15,
      pageSizes: [15, 25, 50, 150, 500, 1500],
      itemsMonth: [
        { id: 1, description: "Januari" },
        { id: 2, description: "Februari" },
        { id: 3, description: "Maret" },
        { id: 4, description: "April" },
        { id: 5, description: "Mei" },
        { id: 6, description: "Juni" },
        { id: 7, description: "Juli" },
        { id: 8, description: "Agustus" },
        { id: 9, description: "September" },
        { id: 10, description: "Oktober" },
        { id: 11, description: "November" },
        { id: 12, description: "Desember" },
      ],

      showFilter: true,
      filterFdivisions: [],
      filterFtemplateReg: [],
      filterFareaBean: [],

      filterStatusUsulan: 0,
      itemsStatusUsulan: [
        { id: 1, description: "1. Menunggu Verifikasi" },
        { id: 2, description: "2. Proses Verifikasi" },
        { id: 3, description: "3. Terverifikasi/Valid" },
        { id: 4, description: "4. Tidak Valid" },
        { id: 5, description: "5. Approve" },
        { id: 6, description: "6. Dikembalikan" },
      ],

      filterPeriodeTahun: 0,
      itemsPeriodeTahun: [2023, 2024, 2025, 2026, 2027],
      filterPeriodeBulan: 0,
      itemsPeriodeBulan: EPeriodeMonths,

      filterJenisJabatanIds: [],

      search: "",

      headers: [
        {
          text: "No",
          value: "number",
          width: "7%",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "avatar",
          width: "6%",
        },
        // { text: 'NIP', value: 'nip', width:"10%" },
        { text: "NIP & Nama PNS", value: "description", width: "19%" },
        { text: "USULAN/PENGAJUAN", value: "ftemplateRegBean", width: "16%" },

        { text: "Periode Usulan", value: "periodeMonth", width: "15%" },
        {
          text: "Status Aprroval",
          value: "approvedStatus",
          width: "10%",
          sortable: false,
          align: "center",
        },
        {
          text: "Verifikator",
          value: "verifikatorBean",
          width: "12%",
          sortable: false,
          align: "center",
        },
        // { text: "Periode", value: "periodeMonth" },
        {
          text: "Aksi",
          value: "actions",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Aksi Tambahan",
          value: "actionsExtended",
          width: "11%",
          sortable: false,
          align: "center",
        },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      // itemsUser: [],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      ftRegistrasi: [new FtRegistrasi(0, "", "")],
      // itemsFDivision: [{ id: 0, kode1: "", description: "" }],

      itemTakedown: new FtRegistrasi()
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFtRegistrasi();
      },
    },
    pageSize: {
      handler: function (value) {
        const refreshData = this.currentPage === 1;
        this.currentPage = 1;
        if (refreshData) {
          console.log("Change PageSize " + value);
          this.fetchFtRegistrasi();
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      },
    },
  },
  computed: {
    statusFilter() {
      return !(
        ((this.filterPeriodeBulan === null || this.filterPeriodeBulan === 0) &&
          (this.filterPeriodeTahun === null || this.filterPeriodeTahun === 0) &&
          (this.filterJenisJabatanIds === null ||
            this.filterJenisJabatanIds.length === 0)) ||
        (this.filterPeriodeBulan !== null &&
          this.filterPeriodeBulan > 0 &&
          this.filterPeriodeTahun !== null &&
          this.filterPeriodeTahun > 0 &&
          this.filterJenisJabatanIds !== null &&
          this.filterJenisJabatanIds.length > 0)
      );
    },
    ERole() {
      return ERole;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    ftRegistrasiFiltered() {
      return this.ftRegistrasi;
    },
    itemsFDivision: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FtRegistrasiModule/updateItemsFDivision", value);
      },
    },
    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateReg",
          value
        );
      },
    },
    itemsFJenisProsedur: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisProsedur;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisProsedur",
          value
        );
      },
    },
    itemsFJenisKenaikanPangkat: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKenaikanPangkat;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKenaikanPangkat",
          value
        );
      },
    },
    itemsFJenisJabatan: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisJabatan;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisJabatan",
          value
        );
      },
    },
    itemsFJenisKartu: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKartu;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKartu",
          value
        );
      },
    },

    itemsUser: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsUser;
      },
      set(value) {
        this.$store.dispatch("FtRegistrasiModule/updateItemsUser", value);
      },
    },
    userAdminPengusul() {
      return this.itemsUser.filter(
          (item) => item.username === this.itemTakedown.modifiedBy
      );
    },
  },
  methods: {
    saveDataEditStatus(item) {
      this.itemSelected = item;
      try {
        Object.assign(
            this.ftRegistrasi[this.itemSelectedIndex],
            this.itemSelected
        );
      } catch (e) {
        this.fetchFtRegistrasi();
        e.toString();
      }
      this.formMode = "";
      this.$refs.refFormEditStatusDialog.setDialogState(false);
    },
    showDialogEditStatus(item) {
      this.itemSelectedIndex = this.ftRegistrasiFiltered.indexOf(item);
      const itemModified = Object.assign({}, item);
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM;
      this.$refs.refFormEditStatusDialog.showDialog(
          this.itemSelectedIndex,
          itemModified,
          this.itemsFDivision
      );
    },
    getJenisDitolak(validasiReasonCode) {
      if (validasiReasonCode === 1) return "Tidak Sesuai";
      else if (validasiReasonCode === 2) return "Tidak Memenuhi Syarat";
    },
    confirmTakedown() {
      let itemTakedown = this.itemTakedown
      itemTakedown.validasiReasonCode = 1
      itemTakedown.validasiStatus = 0;
      itemTakedown.verifikatorBean = 0;
      itemTakedown.submitted = false;

      FtRegistrasiService.updateFtRegistrasi(itemTakedown).then(
          (response) => {
            let newItemFtRegistrasiLog = new FtRegistrasiLog()
            newItemFtRegistrasiLog.ftRegistrasiBean = response.data.id
            newItemFtRegistrasiLog.actionType = 'UPDATE'
            newItemFtRegistrasiLog.oldData = JSON.stringify(this.itemTakedown)?JSON.stringify(this.itemTakedown):"{}"
            newItemFtRegistrasiLog.newData = JSON.stringify(itemTakedown)?JSON.stringify(itemTakedown):"{}"
            newItemFtRegistrasiLog.remarks = `${this.lookupItemsERegistrasiLog(ERegistrasiLog.REJECT).description} karena ${response.data.validasiReasonCode === 1 ? "Berkas Tidak Sesuai" : "Tidak Memenuhi Syarat"}`
            newItemFtRegistrasiLog.modifiedBy = this.currentUser.username

            FtRegistrasiLogService.createFtRegistrasiLog(newItemFtRegistrasiLog).then(
                () => {
                },
                error => {
                  console.log(error.response)
                }
            )
            if (this.userAdminPengusul[0] !== undefined) {
              // console.log("fonte jalan");
              let pesan = `Berkas atas nama ${itemTakedown.description} - ${
                  itemTakedown.nip
              } ${this.getJenisDitolak(
                  itemTakedown.validasiReasonCode
              )} dan tidak dapat diproses lebih lanjut. Dikarenakan ${
                  itemTakedown.approvedReasons !== ""
                      ? itemTakedown.approvedReasons
                      : "Tidak Ada Alasan"
              }.`;
              const simpleMessage = {
                to: this.userAdminPengusul[0].phone,
                subject: "Approval Silayak",
                message: pesan,
              };
              ApiFonnteService.postFonnteSendMessagePublic(simpleMessage).then(
                  () => {}
              );
            }
            this.$refs.refRejectConfirmDialog.setDialogState()
          },
          (err) => {
            console.log(err);
          }
      );
    },
    takedownBerkas(item) {
      this.itemTakedown = item
      this.$refs.refRejectConfirmDialog.showDialog(
          "Apakah ada yakin untuk Mengembalikan Berkas?"
      );
    },
    showRiwayat(item) {
      this.itemSelectedIndex = this.ftRegistrasiFiltered.indexOf(item);
      const itemModified = Object.assign({}, item);
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM;
      this.$refs.refFtRegistrasiLogDialog.showDialog(
          this.itemSelectedIndex,
          itemModified,
      );
    },
    statusUsulan(item){
      if(item.submitted === true && item.verifikatorBean === 0 && item.validasiStatus === 0 && item.validasiReasonCode === 0){
        return "Menunggu Verifikasi"
      } else if(item.submitted === true && item.verifikatorBean === 0 && item.validasiStatus === 0 && item.validasiReasonCode === 1){
        return "Menunggu Re-verifikasi"
      } else if(item.submitted === true && item.verifikatorBean > 0 && item.validasiStatus === 0 && item.validasiReasonCode === 0){
        return "Proses Verifikasi"
      } else if(item.submitted === true && item.verifikatorBean > 0 && item.validasiStatus === 0 && item.validasiReasonCode === 1){
        return "Proses Re-verifikasi"
      } else if((item.validasiStatus === 2 || item.validasiStatus ===0)){
        return item.validasiReasonCode === 1 ? "Dikembalikan" : "Tidak Valid"
      } else if(item.validasiStatus === 1){
        return "Valid"
      }
    },
    alasan(item){
      if((item.validasiStatus === 2 || item.validasiStatus ===0) && item.validasiReasonCode === 1){
        return "Berkas Tidak Sesuai"
      } else if((item.validasiStatus === 2 || item.validasiStatus ===0) && item.validasiReasonCode === 2){
        return "Tidak Memenuhi Syarat"
      }
    },
    downloadBerkas(item) {
      let newItemFtRegistrasiLog = new FtRegistrasiLog()
      newItemFtRegistrasiLog.ftRegistrasiBean = item.id
      newItemFtRegistrasiLog.actionType = 'UPDATE'
      newItemFtRegistrasiLog.oldData = JSON.stringify(item)?JSON.stringify(item):"{}"
      newItemFtRegistrasiLog.newData = JSON.stringify(item)?JSON.stringify(item):"{}"
      newItemFtRegistrasiLog.remarks = this.lookupItemsERegistrasiLog(ERegistrasiLog.DOWNLOAD).description
      newItemFtRegistrasiLog.modifiedBy = this.currentUser.username

      FtRegistrasiLogService.createFtRegistrasiLog(newItemFtRegistrasiLog).then(
          () => {
          },
          error => {
            console.log(error.response)
          }
      )
      const url = FileService.fileSiasnCompressedByFtRegistrasiBean(item.id);
      window.open(url, "_blank");
    },

    showFilterDialog() {
      this.showFilter = !this.showFilter;
    },

    runExtendedFilter() {
      if (this.statusFilter === true) {
        this.snackBarMesage =
          "Periode Tahun, Periode Bulan dan Jenis Jabatan Wajib Diisi Bersamaan";
        this.snackbar = true;
      } else {
        const extendedFilter = new FtRegistrasiFilter();
        extendedFilter.ftemplateRegIds = this.filterFtemplateReg;
        const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
        if (this.filterFtemplateReg.length===0 && hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
          extendedFilter.ftemplateRegIds = this.itemsFTemplateReg.map(
              (item) => item.id
          );
        }

        extendedFilter.fdivisionIds = this.filterFdivisions;
        if (extendedFilter.fdivisionIds.length === 0 && hasAdminOrApproval) {
          extendedFilter.fdivisionIds = this.itemsFDivision.map(
              (item) => item.id
          );
        }

        extendedFilter.validasiStatusIds = [];
        if (this.filterStatusUsulan) {
          extendedFilter.statusUsulanBean = this.filterStatusUsulan;
        }



        extendedFilter.pageNo = this.currentPage;
        extendedFilter.pageSize = this.pageSize;
        extendedFilter.sortBy = "id";
        extendedFilter.order = "DESC";
        extendedFilter.search = this.search;

        extendedFilter.submittedOnly = !(this.filterStatusUsulan === 6 || this.filterStatusUsulan === 0 || this.filterStatusUsulan === null);
        // extendedFilter.statusUsulanBean = 3
        // extendedFilter.submittedOnly = true;


        extendedFilter.periodeTahun =
          this.filterPeriodeTahun == null ? 0 : this.filterPeriodeTahun;
        extendedFilter.periodeBulan =
          this.filterPeriodeBulan == null ? 0 : this.filterPeriodeBulan;
        extendedFilter.fjenisJabatanIds = this.filterJenisJabatanIds;




        if (
          extendedFilter.periodeBulan === 0 &&
          extendedFilter.periodeTahun === 0 &&
          extendedFilter.fjenisJabatanIds.length === 0
        ) {
          FtRegistrasiService.getPostAllFtRegistrasiContainingExt(extendedFilter).then(
            (response) => {
              const { items, totalPages } = response.data;
              // this.ftRegistrasi = items;
              this.ftRegistrasi = items.filter(x => ! (x.submitted === false && x.validasiReasonCode===0 && x.validasiStatus === 0) ) ;
              this.totalPaginationPages = totalPages;
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          FtRegistrasiService.getPostAllFtRegistrasiContainingExtKenaikanPangkat(extendedFilter).then(
            (response) => {
              const { items, totalPages } = response.data;
              // this.ftRegistrasi = items;
              this.ftRegistrasi = items.filter(x => ! (x.submitted === false && x.validasiReasonCode===0 && x.validasiStatus === 0) ) ;
              this.totalPaginationPages = totalPages;
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },

    searchOnEnter(event) {
      if (this.search !== event.target.value) {
        this.currentPage = 1;
        this.search = event.target.value;
        this.fetchFtRegistrasi();
      }
    },

    importExcelCompleted(value) {
      if (value === "OKE") {
        this.fetchFtRegistrasi();
      }
    },

    exportData() {
      const worksheet = XLSX.utils.json_to_sheet(this.ftRegistrasiFiltered.map(item => {
        return {
          "Nama": item.description,
          "NIP": item.nip,
          "Tanggal": item.trDate,
          "Jenis Registrasi": this.lookupFTemplateReg(item.ftemplateRegBean).description,
          "Periode Bulan": item.periodeMonth !== 0? this.lookupPeriodeBulan(item.periodeMonth).description : '-',
          "Periode Tahun": item.periodeYear !== 0? item.periodeYear: '-',
          "Jenis Prosedur": item.fjenisProsedurBean !== 0? this.lookupFJenisProsedur(item.fjenisProsedurBean).description : '-',
          "Jenis Kartu": item.fjenisKartuBean !== 0? this.lookupFJenisKartu(item.fjenisKartuBean).description : '-',
          "Jenis Kenaikan Pangkat": item.fjenisKenaikanPangkatBean !== 0? this.lookupFJenisKenaikanPangkat(item.fjenisKenaikanPangkatBean).description : '-',
          "Jenis Jabatan": item.fjenisJabatanBean !== 0? this.lookupFJenisJabatan(item.fjenisJabatanBean).description : '-',
          "Dinas": this.lookupFDivision(item.fdivisionBean).description,
          "Status Registrasi": this.statusUsulan(item),
          "Alasan Ditolak": item.validasiReasonCode !== 0 ?this.alasan(item) : "-",
          "Keterangan": item.validasiStatus === 2 || item.validasiStatus ===0 ? item.approvedReasons : "-"
        }
      }));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

      // Membuat link download
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `daftar_registrasi_pegawai.xlsx`;  // Nama file yang akan didownload
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    },

    fetchParent() {
      const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
      if (! hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
          (response) => {
            this.itemsFDivision = [response.data];
          },
          (error) => {
            console.log(error.response);
          }
        );
      } else {
        FDivisionService.getAllFDivision().then(
          (response) => {
            this.itemsFDivision = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      FJenisProsedurService.getAllFJenisProsedur().then(
        (response) => {
          this.itemsFJenisProsedur = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisKenaikanPangkatService.getAllFJenisKenaikanPangkat().then(
        (response) => {
          this.itemsFJenisKenaikanPangkat = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisJabatanService.getAllFJenisJabatan().then(
        (response) => {
          this.itemsFJenisJabatan = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisKartuService.getAllFJenisKartu().then(
        (response) => {
          this.itemsFJenisKartu = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      UserService.getAllUser(1, 99999, "id", "DESC", "").then(
        (response) => {
          this.itemsUser = response.data.items;
        },
        (err) => {
          console.log(err);
        }
      );
    },
    fetchFtRegistrasi() {
      this.runExtendedFilter();
    },
    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },
    lookupItemsERegistrasiLog(id) {
      const str = this.itemsERegistrasiLog.filter(
          (x) => x.id === id
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    confirmDialogOke() {
      this.snackBarMesage = "";
      this.dialogLoading = true;
      this.loading = true;
    },
    showDialogNew() {
      this.itemSelectedIndex = -1;
      const itemModified = Object.assign({}, "");
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM;
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified);
    },
    saveDataNew(itemFromRest) {
      this.itemSelected = itemFromRest;
      this.closeDialog();
      /**
       * Setiap data baru harus terlihat
       */
      this.fetchFtRegistrasi();
    },
    deleteDialogShow(item) {
      console.log(item)
    },

    showDialogEdit(item) {
      this.itemSelectedIndex = this.ftRegistrasiFiltered.indexOf(item);
      const itemModified = Object.assign({}, item);
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM;
      this.$refs.refFormDialog.showDialog(
        this.itemSelectedIndex,
        itemModified,
        this.itemsFDivision
      );
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      try {
        Object.assign(
          this.ftRegistrasi[this.itemSelectedIndex],
          this.itemSelected
        );
      } catch (e) {
        this.fetchFtRegistrasi();
        e.toString();
      }
      this.closeDialog();
    },

    approvalDialogShow(item) {
      this.itemSelectedIndex = this.ftRegistrasiFiltered.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      this.itemModified = item;

      this.$refs.refFtRegistrasiApprovalDialog.showDialog(
          this.itemSelectedIndex,
          this.itemModified
      );
      // console.log(JSON.stringify(this.itemModified))

    },

    registrasiApprovalDialogUpdate(itemFromRest) {
      this.itemSelected = itemFromRest;
      this.closeDialog();
      this.fetchFtRegistrasi();
    },

    closeDialog() {
      // this.myConfirmDialog = false
      this.formMode = "";
      // this.$refs.refDeleteConfirmDialog.setDialogState(false);
      // this.$refs.refFormDialog.setDialogState(false);

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FtRegistrasi());
        this.itemSelectedIndex = -1;
      });
    },

    getColorStatusActive(trueFalse) {
      if (trueFalse === true) return "green";
      else if (trueFalse === false) return "gray";
      else return "gray";
    },

    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupPeriodeBulan(periodeBulan) {
      const str = this.itemsMonth.filter((x) => x.id === periodeBulan);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFJenisJabatan(fjenisJabatanBean) {
      const str = this.itemsFJenisJabatan.filter(
        (x) => x.id === fjenisJabatanBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFJenisProsedur(fjenisProsedurBean) {
      const str = this.itemsFJenisProsedur.filter(
        (x) => x.id === fjenisProsedurBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFJenisKartu(fjenisKartuBean) {
      const str = this.itemsFJenisKartu.filter(
        (x) => x.id === fjenisKartuBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFJenisKenaikanPangkat(fjenisKenaikanPangkatBean) {
      const str = this.itemsFJenisKenaikanPangkat.filter(
          (x) => x.id === fjenisKenaikanPangkatBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFTemplateReg(finfratructureTypeBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === finfratructureTypeBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupVerifikator(verifikatorBean) {
      const str = this.itemsUser.filter((x) => x.id === verifikatorBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_low(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.fetchParent();

      const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
      FTemplateRegService.getAllFTemplateReg().then(
          (response) => {
            if (hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
              this.itemsFTemplateReg =  response.data.filter(
                  (item) => item.fdivisionBean === this.currentUser.fdivisionBean
              )
            }else {
              this.itemsFTemplateReg = response.data;
            }

            // this.filterFtemplateReg = this.itemsFTemplateReg.map((item) => item.id);
            this.fetchFtRegistrasi();

          },
          (error) => {
            console.log(error.response);
          }
      )

    }
  },
};
</script>

<style scoped>

</style>