<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <!--      scrollable-->

      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>Berkas Registrasi</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-container>
            <v-card-title class="mb-0 pb-0 font-weight-black">
              {{itemModified.description}}
            </v-card-title>
            <v-card-text class="mb-0 mt-0 pt-0 font-weight-light text-subtitle-1 grey--text">
              {{itemModified.nip}}
            </v-card-text>
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                        outlined
                        v-model="itemModified.validasiReasonCode"
                        :items="itemsDitolak"
                        item-value="id"
                        item-text="description"
                        auto-select-first
                        dense
                        color="blue-grey lighten-2"
                        label="Alasan Berkas Ditolak"
                        hide-details=""
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                        outlined
                        v-model="itemModified.validasiStatus"
                        :items="itemsValidasiStatus"
                        item-value="id"
                        item-text="description"
                        auto-select-first
                        dense
                        color="blue-grey lighten-2"
                        label="Status Validasi"
                        hide-details=""
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-checkbox
                        v-model="itemModified.submitted"
                        label="Berkas Tersubmit"
                    ></v-checkbox>
                  </v-col>
                </v-row>
            </v-card-text>
          </v-container>


          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <close-confirm-dialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></close-confirm-dialog>
    </v-dialog>
  </div>
</template>
  
  <script>
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";

import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";
import FtRegistrasi from "@/models/ft-registrasi";

export default {
  name: "FtRegistrasiApprovalStatusDialog",
  components: { CloseConfirmDialog },
  props: {
    formMode: String,
  },
  data() {
    return {
      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      itemsDitolak: [
        {id:0, description:'Alasan Kosong'},
        {id:1, description:'Berkas Tidak Sesuai'},
        {id:2, description:'Tidak Memenuhi Syarat'},
      ],
      itemsValidasiStatus: [
        {id: 0, description: "Belum Divalidasi"},
        {id: 1, description: "Berkas Valid"},
        {id: 2, description: "Berkas Ditolak"},
      ],
      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FtRegistrasi(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },
      itemsFDivision: [
        { id: 1, kode1: "", description: "" },
        { id: 2, kode1: "", description: "" },
      ],

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
  },
  watch: {},
  methods: {
    showDialog(selectedIndex, item, itemsFDivision) {
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        (this.itemDefault = new FtRegistrasi(0, "", "")),
          (this.itemModified = new FtRegistrasi(0, "", "")),
          (this.selectedIndex = -1);
      }

      this.itemsFDivision = itemsFDivision;
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
        () => {
          // console.log(response.data)
          this.$emit("eventFromFormDialogEdit", this.itemModified);
        },
        (error) => {
          console.log(error);
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = "";

      FtRegistrasiService.getFtRegistrasiById(item.id).then(
        (response) => {
          // console.log(response.data)
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
  
  <style scoped>
</style>